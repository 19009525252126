import React from 'react';
import { navigate } from 'gatsby';
import Helmet from 'react-helmet';
import { Grid, Header, Image } from 'semantic-ui-react';
import 'semantic-ui-less/semantic.less';

import Signature from '../data/graphics/signature.gif';

import '../style/home.css';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = { fadeOut: false }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ fadeOut: true })
    }, 5000)
    setTimeout(() => navigate('/gallery'), 6000)
  }

  render() {
    return (
      <React.Fragment>
        <Helmet
          title={`Ari Hauben Art`}
          meta={[
            { name: 'description', content: 'The official site of Boston-based contemporary artist, Ari Hauben.' },
          ]}
        />
        <Grid
          style={{ margin: 0 }}
          textAlign="center"
          className={!this.state.fadeOut ? "home-container" : "home-container-fade" }
          verticalAlign="middle"
        >
          <Grid.Row className="home-signature-container">
            <Grid.Column>
              <Header as="h1">ARI HAUBEN ART</Header>
              <Image src={Signature} centered />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="home-cta-container">
            <Grid.Column>
              <button onClick={() => navigate('/gallery')}>
                <Header as="h4">SEE GALLERY</Header>
              </button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }
};
